/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'slash-lg': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M13.854 2.146a.5.5 0 010 .708l-11 11a.5.5 0 01-.708-.708l11-11a.5.5 0 01.708 0"/>',
    },
});
